import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-orthodontie-adulte',
  templateUrl: './orthodontie-adulte.component.html',
  styleUrls: ['./orthodontie-adulte.component.scss']
})
export class OrthodontieAdulteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
