import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "topContent"
})
export class TopContentPipe implements PipeTransform {
  transform(value: string[], number: number, innerWidth: number): string {
    result: "";
    if (innerWidth < 650) {
      return value[number][0];
    } else {
      return value[number][1];
    }
    return null;
  }
}
