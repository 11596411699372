import { Component, HostListener, ElementRef, ViewChild } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { toRight, footer } from "./route-animations";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  animations: [toRight, footer]
})
export class AppComponent {
  innerWidth: number;

  state: string = "state1";

  topContentTab: string[][] = [
    [
      "Dentistes les Herbiers",
      "Chirurgien dentiste Dr Bouet - Chirurgien dentiste Dr Nicolas"
    ],
    [
      "Dr Bouet - Dr Nicolas",
      "POLE SANTE NOTRE DAME - 17 Rue St Etienne - 85500 Les Herbiers"
    ],
    ["02 51 61 56 51", "02 51 61 56 51"]
  ];

  open: boolean;
  select: boolean[] = [true, false, false, false, false, false];

  constructor() {}

  ngOnInit() {
    this.open = false;
    this.innerWidth = window.innerWidth;
  }

  @HostListener("window:resize", ["$event"])
  onResizeWidth(event) {
    this.innerWidth = window.innerWidth;
  }

  prepareRoute(outlet: RouterOutlet) {
    if (this.innerWidth <= 769) {
      return (
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData["animationPhone"]
      );
    } else {
      return (
        outlet &&
        outlet.activatedRouteData &&
        outlet.activatedRouteData["animationDesktop"]
      );
    }
  }

  clickOpen() {
    this.open = !this.open;
  }
  clickClose() {
    window.scrollTo(0, 0);
    this.open = !this.open;
  }
  changeSelect(num: number) {
    for (var i = 0; i < this.select.length; i++) {
      this.select[i] = false;
    }
    this.select[num] = !this.select[num];
    this.changeState();
  }

  changeState() {
    this.state = this.state === "state1" ? "state2" : "state1";
  }
}
