import {
  trigger,
  transition,
  style,
  query,
  group,
  state,
  animateChild,
  animate,
  keyframes
} from "@angular/animations";
import { BlockingProxy } from "blocking-proxy";

export const footer = trigger("footerAnimations", [
  state(
    "state1",
    style({
      display: "block"
    })
  ),
  state(
    "state2",
    style({
      display: "block"
    })
  ),
  transition("* <=> *", [
    group([
      style({
        display: "none"
      }),
      animate("0s .5s ease", style({ display: "block" }))
    ])
  ])
]);

export const toRight = trigger("routeAnimations", [
  /*
    Desktop animation
  */
  transition("AccueilPageDesktop => *", slideToLeft()),

  transition("CabinetPageDesktop => AccueilPageDesktop", slideToRight()),
  transition("CabinetPageDesktop => ImplantologiePageDesktop", slideToLeft()),
  transition(
    "CabinetPageDesktop => OrthodontieAdultePageDesktop",
    slideToLeft()
  ),
  transition("CabinetPageDesktop => UrgencePageDesktop", slideToLeft()),
  transition("CabinetPageDesktop => RendezVousPageDesktop", slideToLeft()),

  transition("ImplantologiePageDesktop => AccueilPageDesktop", slideToRight()),
  transition("ImplantologiePageDesktop => CabinetPageDesktop", slideToRight()),
  transition(
    "ImplantologiePageDesktop => OrthodontieAdultePageDesktop",
    slideToLeft()
  ),
  transition("ImplantologiePageDesktop => UrgencePageDesktop", slideToLeft()),
  transition(
    "ImplantologiePageDesktop => RendezVousPageDesktop",
    slideToLeft()
  ),

  transition(
    "OrthodontieAdultePageDesktop => AccueilPageDesktop",
    slideToRight()
  ),
  transition(
    "OrthodontieAdultePageDesktop => CabinetPageDesktop",
    slideToRight()
  ),
  transition(
    "OrthodontieAdultePageDesktop => ImplantologiePageDesktop",
    slideToRight()
  ),
  transition(
    "OrthodontieAdultePageDesktop => UrgencePageDesktop",
    slideToLeft()
  ),
  transition(
    "OrthodontieAdultePageDesktop => RendezVousPageDesktop",
    slideToLeft()
  ),

  transition("UrgencePageDesktop => AccueilPageDesktop", slideToRight()),
  transition("UrgencePageDesktop => CabinetPageDesktop", slideToRight()),
  transition("UrgencePageDesktop => ImplantologiePageDesktop", slideToRight()),
  transition(
    "UrgencePageDesktop => OrthodontieAdultePageDesktop",
    slideToRight()
  ),
  transition("UrgencePageDesktop => RendezVousPageDesktop", slideToLeft()),

  transition("RendezVousPageDesktop => *", slideToRight()),

  /*
    Phone animation
  */
  transition("* <=> *", toFade())
]);

function toFade() {
  const optional = { optional: true };
  return [
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          left: 0,
          width: "100%",
          opacity: 0
        })
      ],
      optional
    ),
    query(
      ":enter",
      [
        style({
          transform: "translateY(50px)"
        }),
        animate("0.7s ease", style({ opacity: 1, transform: "translateY(0)" }))
      ],
      optional
    )
  ];
}

function slideToRight() {
  const optional = { optional: true };
  return [
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          left: 0,
          width: "100%"
        })
      ],
      optional
    ),
    group([
      query(
        ":leave",
        [animate(".5s ease-in-out", style({ transform: "translateX(100%)" }))],
        optional
      ),
      query(
        ":enter",
        [
          style({
            transform: "translateX(-100%)"
          }),
          animate(".5s ease-in-out", style({ transform: "translateX(0)" }))
        ],
        optional
      )
    ])
  ];
}

function slideToLeft() {
  const optional = { optional: true };
  return [
    query(
      ":enter, :leave",
      [
        style({
          position: "absolute",
          left: 0,
          width: "100%"
        })
      ],
      optional
    ),
    group([
      query(
        ":leave",
        [animate(".5s ease-in-out", style({ transform: "translateX(-100%)" }))],
        optional
      ),
      query(
        ":enter",
        [
          style({
            transform: "translateX(100%)"
          }),
          animate("0.5s ease-in-out", style({ transform: "translateX(0)" }))
        ],
        optional
      )
    ])
  ];
}
