import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { AppRoutingModule, routingComponents } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CabinetComponent } from "./cabinet/cabinet.component";
import { ImplantologieComponent } from "./implantologie/implantologie.component";
import { OrthodontieAdulteComponent } from "./orthodontie-adulte/orthodontie-adulte.component";
import { UrgenceComponent } from "./urgence/urgence.component";
import { TopContentPipe } from './top-content.pipe';
import { FooterComponent } from './footer/footer.component';
import { RendezVousComponent } from './rendez-vous/rendez-vous.component';

@NgModule({
  declarations: [
    AppComponent,
    routingComponents,
    CabinetComponent,
    ImplantologieComponent,
    OrthodontieAdulteComponent,
    UrgenceComponent,
    TopContentPipe,
    FooterComponent,
    RendezVousComponent
  ],
  imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
