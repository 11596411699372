import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  HostListener
} from "@angular/core";
import {} from "googlemaps";

@Component({
  selector: "app-cabinet",
  templateUrl: "./cabinet.component.html",
  styleUrls: ["./cabinet.component.scss"]
})
export class CabinetComponent implements OnInit {
  carouselImages: ElementRef[];
  map: google.maps.Map;

  carouselImg: string[] = [
    "../../assets/Cabinet1.jpg",
    "../../assets/Cabinet2.jpg",
    "../../assets/Cabinet3.jpg",
    "../../assets/Cabinet4.jpg",
    "../../assets/CabinetAccueil.jpg"
  ];

  counter = 1;
  size: number = window.innerWidth;

  transformCarouselSlide: string;
  transitionCarouselSlide: string;

  currentImg: number = 0;

  constructor() {}

  ngOnInit() {
    if (this.size > 450) {
      this.transformCarouselSlide = "translateX(" + -450 * this.counter + "px)";
    } else {
      this.transformCarouselSlide =
        "translateX(" + -this.size * this.counter + "px)";
    }
    var angers = { lat: 46.874999, lng: -1.010841 };
    // The map
    var map = new google.maps.Map(document.getElementById("map"), {
      zoom: 15,
      center: angers,
      styles: [
        { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }]
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }]
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }]
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }]
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }]
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }]
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }]
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }]
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }]
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [{ color: "#17263c" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }]
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          stylers: [{ color: "#17263c" }]
        }
      ]
    });
    // The marker, positioned at Uluru
    var marker = new google.maps.Marker({ position: angers, map: map });
  }

  @HostListener("window:resize", ["$event"])
  onResizeWidth(event) {
    this.size = window.innerWidth;
  }

  nextCarousel() {
    if (this.counter >= 6) return;
    this.transitionCarouselSlide = "transform 1s ease-in-out";
    this.counter++;
    console.log(this.size);
    if (this.size > 450) {
      this.transformCarouselSlide = "translateX(" + -450 * this.counter + "px)";
    } else {
      this.transformCarouselSlide =
        "translateX(" + -this.size * this.counter + "px)";
    }
  }

  prevCarousel() {
    if (this.counter <= 0) return;
    this.transitionCarouselSlide = "transform 1s ease-in-out";
    this.counter--;
    console.log(this.size);
    if (this.size > 450) {
      this.transformCarouselSlide = "translateX(" + -450 * this.counter + "px)";
    } else {
      this.transformCarouselSlide =
        "translateX(" + -this.size * this.counter + "px)";
    }
  }

  transitionEndCarousel() {
    if (this.counter === 0) {
      this.transitionCarouselSlide = "none";
      this.counter = 5;
      if (this.size > 450) {
        this.transformCarouselSlide =
          "translateX(" + -450 * this.counter + "px)";
      } else {
        this.transformCarouselSlide =
          "translateX(" + -this.size * this.counter + "px)";
      }
    } else if (this.counter === 6) {
      this.transitionCarouselSlide = "none";
      this.counter = 1;
      if (this.size > 450) {
        this.transformCarouselSlide =
          "translateX(" + -450 * this.counter + "px)";
      } else {
        this.transformCarouselSlide =
          "translateX(" + -this.size * this.counter + "px)";
      }
    }
  }
}
