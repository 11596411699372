import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AccueilComponent } from "./accueil/accueil.component";
import { CabinetComponent } from "./cabinet/cabinet.component";
import { ImplantologieComponent } from "./implantologie/implantologie.component";
import { OrthodontieAdulteComponent } from "./orthodontie-adulte/orthodontie-adulte.component";
import { RendezVousComponent } from "./rendez-vous/rendez-vous.component";
import { UrgenceComponent } from "./urgence/urgence.component";

const routes: Routes = [
  {
    path: "Accueil",
    component: AccueilComponent,
    data: {
      animationDesktop: "AccueilPageDesktop",
      animationPhone: "AccueilPagePhone"
    }
  },
  {
    path: "Cabinet",
    component: CabinetComponent,
    data: {
      animationDesktop: "CabinetPageDesktop",
      animationPhone: "CabinetPagePhone"
    }
  },
  {
    path: "Implantologie",
    component: ImplantologieComponent,
    data: {
      animationDesktop: "ImplantologiePageDesktop",
      animationPhone: "ImplantologiePagePhone"
    }
  },
  {
    path: "Orthodontie-adulte",
    component: OrthodontieAdulteComponent,
    data: {
      animationDesktop: "OrthodontieAdultePageDesktop",
      animationPhone: "OrthodontieAdultePagePhone"
    }
  },
  {
    path: "Urgence",
    component: UrgenceComponent,
    data: {
      animationDesktop: "UrgencePageDesktop",
      animationPhone: "UrgencePagePhone"
    }
  },
  {
    path: "Rendez-vous",
    component: RendezVousComponent,
    data: {
      animationDesktop: "RendezVousPageDesktop",
      animationPhone: "RendezVousPagePhone"
    }
  },
  { path: "", redirectTo: "/Accueil", pathMatch: "full" },
  { path: "**", component: AccueilComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
export const routingComponents = [
  AccueilComponent,
  CabinetComponent,
  ImplantologieComponent,
  OrthodontieAdulteComponent,
  UrgenceComponent,
  RendezVousComponent
];
