import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-urgence',
  templateUrl: './urgence.component.html',
  styleUrls: ['./urgence.component.scss']
})
export class UrgenceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
